import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "html-entities";
import "./_style.scss";

import ChatBottom from "./ChatBottom";
import ChatMessage from "./ChatMessage";
import { setChatToken } from "../../../../modules/actions/setChatToken";

const ChatContent = ({ list, setList }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const chatToken = useSelector((state) => state.chatToken);
    const chatSetting = useSelector((state) => state.chatSetting);

    const defaultAgentId = "40e1e4fc-403a-4ff2-bf22-ea71ece910c1";
    const agentId = useSelector((state) => state.agentId) || defaultAgentId;

    // 로딩 상태 추가
    const [loading, setLoading] = useState(false);

    const sendMessage = async (userText) => {
        const messageList = list;
        const setMessageList = setList;

        let botMessage = "";
        let chatInfo = "";
        const sendText = userText || "";

        if (sendText.trim() === "") return;

        const newMessageList = [
            ...messageList,
            { sender: "user", content: sendText },
        ];
        setMessageList(newMessageList);

        // 로딩 상태 활성화
        setLoading(true);

        // 생성중 답변
        setMessageList([
            ...newMessageList,
            { sender: "assistant", content: "답변 생성 중입니다..." },
        ]);

        const writer = new WritableStream({
            write(chunk) {
                // message 가져오기
                const getMessage = decode(chunk)
                    .split("\n")
                    .map((data) => {
                        if (data.trim() !== "") {
                            const match = data.match(/"message":"(.*?)"/g);
                            return match
                                ? match[0].replace(/"message":"(.*?)"/, "$1")
                                : null;
                        }
                    })
                    .join("")
                    .replaceAll("\\n", "\n");

                chatInfo = chunk;

                botMessage = botMessage + getMessage;
                setMessageList([
                    ...newMessageList,
                    {
                        sender: "assistant",
                        content: botMessage,
                        cot: "false",
                        hyde: "false",
                        refine: "false",
                        dsp: "false",
                        model: "",
                    },
                ]);
            },

            close() {
                //config 추가
                const chunkString = chatInfo.split("\n").filter(Boolean);
                const chatInfoData = JSON.parse(
                    chunkString[chunkString.length - 1].replace("data:", "")
                );
                dispatch(setChatToken(chatInfoData.token));

                // 로딩 상태 비활성화
                setLoading(false);

                setMessageList([
                    ...newMessageList,
                    {
                        sender: "assistant",
                        content: botMessage,
                        cot: chatInfoData.cot.toString() || "false",
                        hyde: chatInfoData.hyde.toString() || "false",
                        refine: chatInfoData.refine.toString() || "false",
                        dsp: chatInfoData.dsp.toString() || "false",
                        model: chatInfoData.model,
                    },
                ]);
            },
        });

        const makeRequest = async (retryCount = 0) => {
            try {
                const response = await fetch("/api/chat/ai/generate/stream", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userInfo.accessToken}`,
                        Accept: "*/*",
                    },
                    body: JSON.stringify({
                        userMessage: sendText,
                        token: chatToken,
                        model: chatSetting.model,
                        hyde: chatSetting.hyde,
                        cot: chatSetting.cot,
                        refine: chatSetting.refine,
                        dsp: chatSetting.dsp,
                        agentId: agentId,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.body
                    .pipeThrough(new TextDecoderStream())
                    .pipeTo(writer);
            } catch (error) {
                console.error("Error:", error);

                if (retryCount < 3) {
                    setMessageList([
                        ...newMessageList,
                        {
                            sender: "assistant",
                            content: retryCount + 1 + "번째 재시도 중",
                        },
                    ]);
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    return makeRequest(retryCount + 1);
                } else {
                    setMessageList([
                        ...newMessageList,
                        {
                            sender: "assistant",
                            content:
                                "현재 사용량이 많아 서버가 불안정합니다. 조금 후에 다시 시도해주세요.",
                        },
                    ]);
                    setLoading(false);
                }
            }
        };

        await makeRequest();
    };

    return (
        <div className="chat-content">
            <ChatMessage messageList={list} loading={loading} />
            <ChatBottom sendMessage={sendMessage} />
        </div>
    );
};

export default ChatContent;
