import React, { useEffect, useState } from "react";
import "./_style.scss";
import AgentListTable from "../../../../molecules/Agent/AgentListTable";
import axiosInstance from "../../../../../modules/util/axiosInstance";
import { TRUE } from "sass";

const AgentSelectModal = ({ onClose, onSelectAgent }) => {
    const [agents, setAgentsList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        try {
            axiosInstance
                .get(`/api/agent/agentlist?page=${page}&size=5`)
                .then((e) => {
                    const responseData = e.data.data;
                    setAgentsList(responseData?.itemList);
                    setTotalPage(responseData?.total);
                });
        } catch (e) {}
    }, [page]);

    return (
        <div className="agent-select-modal-overlay" onClick={onClose}>
            <div
                className="agent-select-modal"
                onClick={(e) => e.stopPropagation()}
            >
                <button className="close-button" onClick={onClose}>
                    X
                </button>
                <h2>에이전트 선택</h2>
                <div>
                    <AgentListTable
                        agentList={agents}
                        page={page}
                        setPage={setPage}
                        totalPage={totalPage}
                        usingSelect={true}
                        onClose={onClose}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgentSelectModal;
