import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://92d1bd5fd472f450233df0c556c9e4eb@o4508765582196736.ingest.us.sentry.io/4508765598121984",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: process.env.REACT_APP_DEV_ENV || "feature",
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const title = process.env.REACT_APP_TITLE || "DO-SOLUTION";
document.title = title;

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
